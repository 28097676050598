import React, { useState, useEffect } from 'react'
import { Accordion, Button, Card, AccordionContext, useAccordionToggle } from "react-bootstrap"
import CountrySelect from '../components/country-select'
import ButtonLoader from '../components/button-loader'
import { useContext } from 'react'
import AccordionToggler from '../components/AccordionToggler'
import { MyAccordionToggler, MyAccordionCollapse } from '../components/my-accordion'
import { FaPlus, FaMinus } from "react-icons/fa";

// const fields = [ "city", "country", "dni", "email", "last_name", "name", "phone", "province", "zip_code", "street_opt", "number_opt" ];
const errorTranslate = {
    "city": "Localidad",
    "country": "País",
    "dni": "DNI",
    "email": "Email",
    "last_name": "Apellido",
    "name": "Nombre",
    "phone": "Teléfono",
    "province": "Provincia",
    "zip_code": "Código Postal",
    "street_opt": "Calle",
    "number_opt": "Número",
    "street_ln_1": "Línea de calle",
}

const CartV2DatosCard = ({ setNeedRefreshShipment, refreshShipmentIfNeeded, clientAddress, setClientAddress, clientNotes, setClientNotes, 
    currentAccordionStep, addressErrors }) => {

    // const [addressValidationMessage, setAddressValidationMessage] = useState('')
    // const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false)

    const onAddresChange = (addressType, e) => {
        let { name, value } = e.target
        let address = { ...clientAddress[addressType] }
        address[name] = value
        setClientAddress({ ...clientAddress, [addressType]: address })
        console.log(addressType, name)
        if(addressType === 'ship_address' && (name === 'zip_code' || name === 'city')){
            console.log('refresh shipment')
            setNeedRefreshShipment(true)
        }
    }

    const onCountryChange = (addressType, option) => {
        let newAddress = clientAddress[addressType]
        newAddress.country = option.value
        newAddress.country_code = option.code
        setClientAddress({ ...clientAddress, [addressType]: newAddress })
        if(addressType === 'ship_address'){
            setNeedRefreshShipment(true)
        }
    }

    return <Card className='card-accordion'>
        <MyAccordionToggler eventKey="0" as={Card.Header}>
        {/* currentAccordionStep */}
            <h5> {currentAccordionStep === "0" ? <FaPlus /> : <FaMinus />} Tus Datos</h5>
        </MyAccordionToggler>
        <MyAccordionCollapse eventKey="0">
            <Card.Body>
                
                <div className="box">
                    
                    <form>
                        <div className="fields">

                            <div className='row'>
                                <div className='col col-6'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.name ? " is-invalid" : "")}
                                        name="name"
                                        value={clientAddress.ship_address?.name ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Nombre"
                                        required />
                                </div>
                                <div className='col col-6'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.last_name ? " is-invalid" : "")}
                                        name="last_name"
                                        value={clientAddress.ship_address?.last_name ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Apellido"
                                        required />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.email ? " is-invalid" : "")}
                                        name="email"
                                        value={clientAddress.ship_address?.email ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Email"
                                        required />
                                </div>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.phone ? " is-invalid" : "")}
                                        name="phone"
                                        value={clientAddress.ship_address?.phone ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Télefono / Celular"
                                        required />

                                </div>
                                <div className='col col-4'>
                                    <input
                                            type="text"
                                            className={"form-control" + (addressErrors?.dni ? " is-invalid" : "")}
                                            name="dni"
                                            value={clientAddress.ship_address?.dni ?? ""}
                                            onChange={(e) => onAddresChange('ship_address', e)}
                                            placeholder="Documento de identidad"
                                            required
                                        />
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col col-9'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.street_opt ? " is-invalid" : "")}
                                        name="street_opt"
                                        value={clientAddress.ship_address?.street_opt ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Calle"
                                        required
                                    />
                                </div>
                                <div className='col col-3'>
                                    <input
                                        type="number"
                                        className={"form-control" + (addressErrors?.number_opt ? " is-invalid" : "")}
                                        name="number_opt"
                                        value={clientAddress.ship_address?.number_opt ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Número"
                                        required
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="floor_opt"
                                        value={clientAddress.ship_address?.floor_opt ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Piso (opcional)"
                                    />
                                </div>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="apartment_opt"
                                        value={clientAddress.ship_address?.apartment_opt ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Depto. (Opcional)"
                                    />
                                </div>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="street_ln_2"
                                        value={clientAddress.ship_address?.street_ln_2 ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Entre Calles (Opcional)"
                                    />
                                </div>
                            </div>



                            <div className='row'>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.city ? " is-invalid" : "")}
                                        name="city"
                                        value={clientAddress.ship_address?.city ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Localidad / Ciudad"
                                        required />
                                </div>
                                <div className='col col-4'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.province ? " is-invalid" : "")}
                                        name="province"
                                        value={clientAddress.ship_address?.province ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Provincia / Estado"
                                        required />
                                </div>
                                <div className='col col-4'>
                                    <CountrySelect
                                        name="country"
                                        value={clientAddress.ship_address?.country ?? ""}
                                        onCountrySelect={option => onCountryChange('ship_address', option)}
                                        isRequired={true}
                                    />
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col col-12'>
                                    <input
                                        type="text"
                                        className={"form-control" + (addressErrors?.zip_code ? " is-invalid" : "")}
                                        name="zip_code"
                                        value={clientAddress.ship_address?.zip_code ?? ""}
                                        onChange={(e) => onAddresChange('ship_address', e)}
                                        placeholder="Código Postal"
                                        style={{ marginBottom: 0 }} />

                                    <div style={{ color: "#43393b", fontSize: "13px", margin: "5px 0 20px 5px" }}>
                                        Consulte su Código Postal <a href='https://codigopostal.com.ar/' target='_blank' style={{ color: "#16b54c" }}>aquí</a>
                                    </div>
                                </div> 
                            </div>


                            <div className="button-holder">
                                <input
                                    type="checkbox"
                                    className="ml-1 mr-2"
                                    name="same_billing_address"
                                    checked={clientAddress.sameBillingAddress}
                                    onChange={() => setClientAddress({ ...clientAddress, sameBillingAddress: !clientAddress.sameBillingAddress })} />
                                <label> Usar misma dirección para la facturación</label>
                            </div>
                            {!clientAddress.sameBillingAddress &&
                                <>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={clientAddress.bill_address?.name ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Nombre"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={clientAddress.bill_address?.last_name ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Apellido"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="dni"
                                        value={clientAddress.bill_address?.dni ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Documento de identidad"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        value={clientAddress.bill_address?.phone ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Teléfono / Celular"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={clientAddress.bill_address?.email ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Email"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="street_ln_1"
                                        value={clientAddress.bill_address?.street_ln_1 ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Línea de calle 1"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="street_ln_2"
                                        value={clientAddress.bill_address?.street_ln_2 ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Línea de calle 2"
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        value={clientAddress.bill_address?.city ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Localidad / Ciudad"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'} />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="province"
                                        value={clientAddress.bill_address?.province ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Provincia / Estado"
                                        required={clientAddress.sameBillingAddress ? '' : 'required'} />
                                    <CountrySelect
                                        name="country"
                                        value={clientAddress.bill_address?.country ?? ""}
                                        onCountrySelect={(option) => onCountryChange('bill_address', option)}
                                        isRequired={clientAddress.sameBillingAddress ? false : true} />
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="zip_code"
                                        value={clientAddress.bill_address?.zip_code ?? ""}
                                        onChange={(e) => onAddresChange('bill_address', e)}
                                        placeholder="Código Postal" />
                                </>
                            }
                        </div>
                        <div className="note">
                            <p>Notas del pedido</p>
                            <textarea
                                className="form-control"
                                name="order_note"
                                value={clientNotes}
                                onChange={(e) => setClientNotes(e.target.value)}
                                rows={1}
                                placeholder="Notas sobre tu pedido, por ejemplo, notas especiales para la entrega."></textarea>
                        </div>
                    </form>
                </div>

                <MyAccordionToggler as="button" className="accordion-btn" eventKey="1">
                {/* <MyAccordionToggler as="button" className="accordion-btn" eventKey="1" onToggleCallback={refreshShipmentIfNeeded}> refresh en cartV2::handleAccordionStepChange() */}
                    Continuar
                </MyAccordionToggler>

                {
                    Object.keys(addressErrors).length > 0 &&
                    <div className="alert alert-danger mt-3" role="alert">
                        Por favor, complete los campos requeridos: {Object.keys(addressErrors).map((key, index) => {
                            return <span key={key}>{errorTranslate[key]}{index < Object.keys(addressErrors).length - 1 ? ', ' : ''}</span>
                        })}
                    </div>
                }

            </Card.Body>
        </MyAccordionCollapse>

    </Card>

}

export default CartV2DatosCard