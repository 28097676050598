import React, { createContext, useState, useContext, useRef, useEffect } from 'react';

const MyAccordionContext = createContext();

const MyAccordion = ({ currentActiveKey, children, onActiveKeyChange, ...props }) => {
    return (
        <div className="accordion">
            <MyAccordionContext.Provider value={{ currentActiveKey, onActiveKeyChange }}>
                {children}
            </MyAccordionContext.Provider>
        </div>
    );
};

const MyAccordionToggler = ({ as: Component = 'div', children, eventKey, onToggleCallback, ...props }) => {
    const { currentActiveKey, onActiveKeyChange } = useContext(MyAccordionContext);
    const isCurrentOpen = currentActiveKey === eventKey;

    const decoratedOnClick = () => {
        const continueToggle = true;
        if(onToggleCallback){
            const responde = onToggleCallback(eventKey);
            if(responde === false){
                continueToggle = false;
            }
        }

        if(continueToggle){
            onActiveKeyChange(isCurrentOpen ? null : eventKey);
        }
    };

    return (
        <Component onClick={decoratedOnClick} {...props}>
            {children}
        </Component>
    );
};

const MyAccordionCollapse = ({ eventKey, children, ...props }) => {
    const { currentActiveKey } = useContext(MyAccordionContext);
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px');
    const [ isFirstRender, setIsFirstRender ] = useState(true)

    useEffect(() => {
        if (eventKey === currentActiveKey) {
            setHeight(`${contentRef.current.scrollHeight}px`);
            
            if(!isFirstRender){
                setTimeout(() => {
                    // contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    const yOffset = -180; // Threshold offset
                    const y = contentRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }, 200);
            }

            setTimeout(() => {
                setHeight(prevState => prevState == "0px" ? "0px" : 'none');
            }, 500);

        } else {
            setHeight('0px');
        }
        setIsFirstRender(false)
    }, [currentActiveKey, eventKey]);

    return (
        <div
            className="collapse-wrapper"
            style={{
                overflow: 'hidden',
                transition: 'max-height 0.3s ease',
                maxHeight: height,
            }}
        >
            <div ref={contentRef}>{children}</div>
        </div>
    );
};

export { MyAccordion, MyAccordionToggler, MyAccordionCollapse };

// import React, { createContext, useState, useContext } from 'react'

// const MyAccordionContext = createContext();

// const MyAccordion = ({ currentActiveKey, children, onActiveKeyChange, ...props }) =>  {

//     return <div className='accordion'>
//         <MyAccordionContext.Provider value={{ currentActiveKey, onActiveKeyChange }}>
//             {children}
//         </MyAccordionContext.Provider>
//     </div> 

// }

// const MyAccordionToggler = ({ as: Component = "div", children, eventKey, onToggleCallback, ...props }) => {
//     const { currentActiveKey, onActiveKeyChange } = useContext(MyAccordionContext);
//     const isCurrentOpen = currentActiveKey === eventKey;

//     const decoratedOnClick = () => {
//         onActiveKeyChange(isCurrentOpen ? null : eventKey);
//     }

//     return <Component onClick={decoratedOnClick} {...props}>
//         {children}
//     </Component>
// }

// const MyAccordionCollapse = ({eventKey, children, ...props}) => {

//     const { currentActiveKey } = useContext(MyAccordionContext);
    
//     return <div className={`collapse${ eventKey === currentActiveKey ? ' show' : '' }`}>
//         {children}
//     </div>

// }

// export { MyAccordion, MyAccordionToggler, MyAccordionCollapse }