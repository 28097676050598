import axios from 'axios'

export const PROCESS_MP_PAYMENT = 'PROCESS_MP_PAYMENT'
export const PROCESS_PAYPAL_PAYMENT = 'PROCESS_PAYPAL_PAYMENT'
export const PROCESS_GETNET_PAYMENT = 'PROCESS_GETNET_PAYMENT'
export const PROCESS_MODO_PAYMENT = 'PROCESS_MODO_PAYMENT'


export const processMercadoPagoPayment = (status,params) => ({
  type: PROCESS_MP_PAYMENT,
  payload: axios.post(`/account/process-mp-payment/${status}`,params)
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const processPaypalPayment = (token,payerId) => ({
  type: PROCESS_PAYPAL_PAYMENT,
  payload: axios.post(`/account/process-paypal-payment`,{token, payerId})
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const processGetnetPayment = (status, order_id, payment_intent_id) => ({
  type: PROCESS_GETNET_PAYMENT,
  payload: axios.post(`/account/process-getnet-payment/${status}`,{order_id, payment_intent_id})
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const processModoPayment = (status, order_id, payment_intent_id) => ({
  type: PROCESS_MODO_PAYMENT,
  payload: axios.post(`/account/process-modo-payment/${status}`,{order_id, payment_intent_id})
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})