import React, { useState, useEffect } from 'react'
import { Accordion, Button, Card, FormCheck } from "react-bootstrap"
import AccordionToggler from '../components/AccordionToggler'
import { MyAccordionToggler, MyAccordionCollapse } from '../components/my-accordion'
import { FaPlus, FaMinus } from "react-icons/fa";

const CartV2EnvioCard = ({ shipment, setShipment, selectedOfficeId, setSelectedOfficeId, isCalculatingShipment, shipmentInfo, refreshShipmentIfNeeded, currentAccordionStep }) => {

  const domicilioCost = shipmentInfo.isFreeShip ? "Gratis" : `${shipmentInfo.shipmentAmount?.currency ?? ""} ${shipmentInfo.shipmentAmount?.amount ?? ""}`
  const officeCost = shipmentInfo.isFreeShip ? "Gratis" : `${shipmentInfo.shipmentAmount?.currency ?? ""} ${shipmentInfo.possibleShippingOffices?.shipment_cost}`

  return <Card className='card-accordion'>
    <MyAccordionToggler as={Card.Header} eventKey="1" onToggleCallback={refreshShipmentIfNeeded}>
      <h5> {currentAccordionStep === "0" ? <FaPlus /> : <FaMinus />} Envío / Retiro</h5>
    </MyAccordionToggler>
    <MyAccordionCollapse eventKey="1">
        <Card.Body>

          <h6>Retiro por sucursal</h6>
          <div className='shipment-selector-wrapper mb-4'>
            <FormCheck>
                <input
                  id='radio_retiro_centro'
                  type="radio"
                  name="shippment"
                  value="2"
                  className="form-check-input"
                  checked={shipment === "2"}
                  onChange={(e) => setShipment(e.target.value)} />
                <label htmlFor='radio_retiro_centro' className={`form-form-check-label${shipment === "2" ? " active" : ""}`}>Sucursal Centro</label>
              </FormCheck>
            {/* <div 
              className='shipment-selector-check'
            >

              <input
                id='radio_retiro_centro'
                type="radio"
                name="shippment"
                value="2"
                className="form-check-input"
                checked={shipment === "2"}
                onChange={(e) => setShipment(e.target.value)} />
            </div>
                 */}
            <FormCheck>
                <input
                  id='radio_retiro_belgrano'
                  type="radio"
                  name="shippment"
                  value="3"
                  className="form-check-input"
                  checked={shipment === "3"}
                  onChange={(e) => setShipment(e.target.value)} />
                <label htmlFor='radio_retiro_belgrano' className={`form-form-check-label${shipment === "3" ? " active" : ""}`}>Sucursal Belgrano</label>
              </FormCheck>

              </div>

              <h6>Envío <span className='text-muted' style={{fontSize : "0.8rem"}} >{(shipmentInfo.shipmentAmount == null && !isCalculatingShipment) ? "Revise sus datos para ver las opciones de envío" : ""}</span></h6>
              <div className='shipment-selector-wrapper'>

                <FormCheck>
                  <input
                    id='radio_envio_domicilio'
                    type="radio"
                    name="shippment"
                    value="1"
                    className="form-check-input"
                    checked={shipment === "1"}
                    onChange={(e) => setShipment(e.target.value)} 
                    disabled={isCalculatingShipment || shipmentInfo.shipmentAmount == null}
                  />
                  <label htmlFor='radio_envio_domicilio' className={`form-form-check-label${shipment === "1" ? " active" : ""}`}>Envío a domicilio ({ isCalculatingShipment ? "Calculando..." : 
                    (shipmentInfo.shipmentAmount == null ? "No disponible" : domicilioCost)
                  })</label>
                </FormCheck>


                <FormCheck className={((shipmentInfo.possibleShippingOffices?.offices.length ?? 0) == 0) ? 'disabled' : ""}>
                  <input
                    id='radio_punto_retiro'
                    type="radio"
                    name="shippment"
                    value="11"
                    className="form-check-input"
                    checked={shipment === "11"}
                    onChange={(e) => { setShipment(e.target.value); setSelectedOfficeId(shipmentInfo.possibleShippingOffices?.offices[0]?.id ?? null) }}
                    disabled={( (shipmentInfo.possibleShippingOffices?.offices.length ?? 0) == 0) ? 'disabled' : ''}
                  />
                  <label htmlFor='radio_punto_retiro' className={`form-form-check-label${shipment === "11" ? " active" : ""}`}>Punto de retiro  
                    ({ isCalculatingShipment ? "Calculando..." : 
                      (shipmentInfo.possibleShippingOffices?.offices.length ?? 0) == 0 ? "No disponible" : officeCost
                    })
                  </label>
              </FormCheck>
          </div>

            {(shipment != "11" || (shipmentInfo.possibleShippingOffices?.offices.length ?? 0) <= 0) ?
                (
                  <div></div>
                ) : (
                  <>
                    <div className='sucursales_checkboxes mt-4 ml-2'>
                      {shipmentInfo?.possibleShippingOffices?.offices.map((office, index) => {
                        return (
                          <FormCheck key={office.id}>

                            <input
                              id={'radio_punto_retiro__' + office.id}
                              type="radio"
                              name="selected_office_id"
                              value={office.id}
                              className="form-check-input"
                              checked={shipment === "11" && selectedOfficeId == office.id}
                              onChange={(e) => { setShipment("11"); setSelectedOfficeId(e.target.value) }}
                            />
                            <label htmlFor={'radio_punto_retiro__' + office.id}>{office.full_address} <strong>{office.localidad}, {office.provincia}</strong></label>
                          </FormCheck>
                        )
                      })}
                    </div>
                    <div className='sucursales_message'>Para punto de retiro el tiempo aproximado es de 5 días hábiles.</div>
                  </>
                )
              }

          <div className='mt-3'>
            <MyAccordionToggler as='button' eventKey="2" className="accordion-btn">
                  Continuar
              </MyAccordionToggler>
          </div>


        </Card.Body>
    </MyAccordionCollapse>

  </Card>

}

export default CartV2EnvioCard