import React, { useState, useEffect } from 'react'
import { Accordion, Button, Card, Tooltip, OverlayTrigger } from "react-bootstrap"
import ButtonLoader from '../components/button-loader'
import { MyAccordionToggler, MyAccordionCollapse } from '../components/my-accordion'
import { FaPlus, FaMinus } from "react-icons/fa";

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Válido solo para pago en dolares. Para ello seleccione USD como moneda.
    </Tooltip>
  );
  

const CartV2PaymentCard = ({ userCurrency, payment, setPayment, currentAccordionStep }) => {

    const [validationMessage, setValidationMessage] = useState('') // FIXME

    const onMakeOrder = () => {

    }

    return <Card className='card-accordion'>
        <MyAccordionToggler as={Card.Header} eventKey="2">
            <h5> {currentAccordionStep === "0" ? <FaPlus /> : <FaMinus />} Medios de Pago</h5>
        </MyAccordionToggler>
        <MyAccordionCollapse eventKey="2">
            <Card.Body>

                <div lg={4} className="payment">
                    <div className="box grey">
                        <div className={"button-holder" + (payment === "Transferencia" ? " active" : "")}>
                            <input
                                id='radio_transferencia'
                                type="radio"
                                name="payment"
                                value="Transferencia"
                                checked={payment === "Transferencia"}
                                onChange={(e) => setPayment(e.target.value)} />
                            <label htmlFor='radio_transferencia'>Transferencia bancaria directa</label>
                        </div>
                        <div className={"button-holder" + (payment === "Getnet" ? " active" : "")}>
                            <input
                                id='radio_getnet'
                                type="radio"
                                name="payment"
                                value="Getnet"
                                checked={payment === "Getnet"}
                                disabled={userCurrency == 'USD' ? 'disabled' : ''}
                                onChange={(e) => setPayment(e.target.value)} />
                            <label htmlFor='radio_getnet'>Pago con Tarjeta (Getnet)</label>
                        </div>
                        <div className={"button-holder" + (payment === "Mercado Pago" ? " active" : "")}>
                            <input
                                id='radio_mercado_pago'
                                type="radio"
                                name="payment"
                                value="Mercado Pago"
                                checked={payment === "Mercado Pago"}
                                disabled={userCurrency == 'USD' ? 'disabled' : ''}
                                onChange={(e) => setPayment(e.target.value)} />
                            <label htmlFor='radio_mercado_pago'>Mercado Pago</label>
                        </div>
                        <div className={"button-holder" + (payment === "MODO" ? " active" : "")}>
                            <input
                                id='radio_modo'
                                type="radio"
                                name="payment"
                                value="MODO"
                                checked={payment === "MODO"}
                                disabled={userCurrency == 'USD' ? 'disabled' : ''}
                                onChange={(e) => setPayment(e.target.value)} />
                            <label htmlFor='radio_modo'>MODO</label>
                        </div>
                        <div className={"button-holder" + (payment === "Paypal" ? " active" : "")} style={{position:"relative"}}>
                            <input
                                id='radio_paypal'
                                type="radio"
                                name="payment"
                                value="Paypal"
                                disabled={userCurrency == 'ARS' ? 'disabled' : ''}
                                checked={payment === "Paypal"}
                                onChange={(e) => setPayment(e.target.value)} />
                            <label htmlFor='radio_paypal' className={userCurrency == 'ARS' ? 'label-disabled' : ''} >PayPal </label>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}>
                                <span variant="success" className='overlay-tooltip-btn' >?</span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    {/* {payment === "Mercado Pago" &&
                        <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/468X60.jpg"
                            title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"
                            width="468" height="60" style={{ maxWidth: '100%', marginBottom: '20px' }} />
                    } */}
                    
                </div>


            </Card.Body>
        </MyAccordionCollapse>

    </Card>

}

export default CartV2PaymentCard