import { Switch, Route, Redirect } from 'react-router-dom'
import Layout from './../layout'
import Home from '../pages/home'
import Login from '../pages/login'
import Cart from '../pages/cart'
import Product from '../pages/product'
import HowToBuy from '../pages/how-to-buy'
import Contact from '../pages/contact'
import News from '../pages/news'
import AboutUs from '../pages/about-us'
import NotFound  from '../pages/not-found'
import UserRoutes from './user-routes'
import PrivateRoute from './private-route'
import Offers from '../pages/offers'
import Recommended from '../pages/recommended'
import Authors from '../pages/authors'
import ProductsList from '../pages/products-list'
import GiftCard from '../pages/giftcard'
import Registration from '../pages/registration'
import RegistrationMessage from '../pages/registration-message'
import Policies from '../pages/policies'
import RecoverPassword from '../pages/recover-password'
import RecoverPasswordMessage from '../pages/recover-password-message'
import RecoverPasswordValidation from '../pages/recover-password-validation'
import Tags from '../pages/tags'
import Presale from '../pages/presale'
import NewsletterThanks from '../pages/newsletter-thanks'
import SpecialOffers from '../pages/special-offers'
import LoginGoogle from '../pages/login-google'
import CartV2 from '../pages/cartv2'


const Wrapper = ({children}) => <Layout>{children}</Layout>

const Routes = props =>
  <Wrapper>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/productos/:category?" component={ProductsList}/>
      <Route path="/giftcards" component={GiftCard}/>
      <Route path="/login/google" component={LoginGoogle}/>
      <Route path="/login" component={Login}/>
      <Route path="/registracion" component={Registration}/>
      <Route path="/registracion-mail" component={RegistrationMessage}/>
      <Route path="/cart" component={Cart}/>
      {/* <Route path="/cart-v2" component={CartV2}/> */}
      <Route path="/producto/:id" component={Product}/>
      <Route path="/como-comprar" component={HowToBuy}/>
      <Route path="/nosotros" component={AboutUs}/>
      <Route path="/contacto" component={Contact}/>
      <Route path="/novedades" component={News}/>
      <Route path="/autores/:slug" component={Authors}/>
      <Route path="/tags/:slug" component={Tags}/>
      {/*<Route path="/marcas/:slug" component={Brands}/>*/}
      <Route path="/recomendados" component={Recommended}/>
      <Route path="/ofertas" component={Offers}/>
      <Route path="/preventa" component={Presale}/>
      {/*<Route path="/oferta-ninez" component={SpecialOffers}/>*/}
      <Route path="/politicas" component={Policies}/>
      <Route path="/news-thanks" component={NewsletterThanks}/>
      <Route path="/recuperar-clave" component={RecoverPassword}/>
      <Route path="/recuperar-clave-mail" component={RecoverPasswordMessage}/>
      <Route path="/recuperar-clave-validacion" component={RecoverPasswordValidation}/>
      <PrivateRoute path="/cuenta/" component={UserRoutes} {...props} />
      <Route path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  </Wrapper>
  
export default Routes