import React, { useState, useEffect } from 'react'
import { Button, Card } from "react-bootstrap"
import ButtonLoader from '../components/button-loader';

const formatNumber = (number) => {
  return new Intl.NumberFormat('es-AR').format(number);
}

const CartV2ResumenCard = ({ cart, shipment, isCalculatingShipment, shipmentInfo, refreshShipmentIfNeeded, isMakingOrder, setIsMakingOrder, validationMessage, onMakeOrder }) => {

    let shipmentCost = "Gratis";
    if(shipment == "2" || shipment == "3"){
        shipmentCost = "-";
    }else if(!shipmentInfo.isFreeShip){
        if(shipment == "1"){
            shipmentCost = `${shipmentInfo.shipmentAmount?.currency} ${formatNumber(shipmentInfo.shipmentAmount?.amount)}`
        }else if(shipment == "11"){
            shipmentCost = `${shipmentInfo.shipmentAmount?.currency} ${formatNumber(shipmentInfo.possibleShippingOffices?.shipment_cost ?? "non")}`
        }
    }

    
    const calcTotal = () => {
      // console.log("calcTotal", shipment, shipmentInfo, cart)
        if (shipment === "1") {
            return cart?.totalPrice.currency + " " + formatNumber(cart?.totalPrice.amount);
        } else if (shipment === "11") {
            if(shipmentInfo?.isFreeShip){
              return cart?.totalPrice.currency + " " + formatNumber(cart?.totalPrice.amount);
            }
            return cart?.totalPrice.currency + " " + formatNumber(cart?.productPrice.amount + shipmentInfo?.possibleShippingOffices?.shipment_cost - (cart?.couponAmount.amount + cart?.giftCardAmount.amount + cart?.discountAmount.amount));
        } else {
            return cart?.productPrice.currency + " " + formatNumber(cart?.productPrice.amount - (cart?.couponAmount.amount + cart?.giftCardAmount.amount + cart?.discountAmount.amount));
        }
    }
    
    return <>
      <Card className='resumen-card'>
          <Card.Header>
              Tu pedido
          </Card.Header>
          <Card.Body>

              <div className="row mb-2">
                <div className="col-12 products-label">
                  Productos
                </div>
              </div>
              {cart?.cartItems.map((cartItem, i) =>
                    <div className="row mb-2" key={i}>
                      <div className="col-8 resumen_product">
                        <a href={`/producto/${cartItem.product?.slug}`} target='_blanck'> {cartItem.product.title}</a>
                        <span style={{color: '#000'}}> {`(x${cartItem.quantity})`}</span>
                      </div>
                      <div className="col-4 price">{cartItem.product.price.currency} {formatNumber(cartItem.product.price.amount * cartItem.quantity)}</div>
                    </div>
              )}
              
              <div className="row mt-3">
                <div className="col-8 subtotal-label">
                  Subtotal
                </div>
                <div className="col-4 subtotal-price">
                  {cart?.productPrice.currency} {formatNumber(cart?.productPrice.amount)}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-8 shipment-label">
                  Envío
                </div>
                <div className="col-4 shipment-price">
                  {isCalculatingShipment ? "Calculando..." : shipmentCost}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-8 total-label">
                  Total
                </div>
                <div className="col-4 total-price">
                  {calcTotal()}
                </div>
              </div>

            </Card.Body>
          </Card>

          <Card className='my-3 confirm-card'>
            <Card.Body>
              <div>
                  <ButtonLoader
                          isLoading={isMakingOrder}
                          loadingMessage="Realizando pedido"
                          type="button"
                          className={`confirm-button`}
                          onClick={() => onMakeOrder()}>
                          Realizar pedido
                      </ButtonLoader>
                  {validationMessage != '' &&
                      <p className='pl-2' style={{ color: 'red' }}>{validationMessage}</p>}
              </div>

              <p className='mt-3'>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web,
                              gestionar el acceso a tu cuenta y otros propósitos descritos en nuestra <a href="/politicas">política de privacidad.</a></p>
          </Card.Body>
      </Card>
    </>

}

export default CartV2ResumenCard