import React, {useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import history from '../history'

const CheckoutMODO = props => { 
  const [order, setOrder ] = useState(null)
  const [paymentError, setPaymentError] = useState(false)
  // const [isLoadingScript, setIsLoadingScript] = useState(true)

  const baseUrl = window.glbl_base_url ?? "https://entelequia.com.ar/";

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = window.glbl_modo_bundle_src ?? "https://ecommerce-modal.modo.com.ar/bundle.js";
      script.async = true;
      // script.setAttribute("data-testid", "digital-checkouta");
      // script.setAttribute("id", "digital-checkoutb");
      
      script.onload = () => {
        resolve();
      };
      
      script.onerror = () => {
        reject(new Error("Script load error"));
      };
      
      document.body.appendChild(script);
    });
  }
  
  useEffect(() => {


    let {order: _order, paymentMODODetail: _paymentMODODetail} = props.location.state ?? {}
    
    if(_order == null || _paymentMODODetail == null || _paymentMODODetail.payment_intent_id == null || _paymentMODODetail.payment_access_token == null){

      setPaymentError(true)
      
    } else{
        setOrder(_order)

        let entelequia_modo_order = { order_id : _order.id, payment_intent_id : _paymentMODODetail.payment_intent_id}
        localStorage.setItem('entelequia_modo_order', JSON.stringify(entelequia_modo_order));

        console.log("_paymentMODODetail",_paymentMODODetail)

        loadScript()
          .then(() => {

            var modalObject = {
                qrString: _paymentMODODetail.payment_qr,
                checkoutId: _paymentMODODetail.payment_intent_id,
                deeplink:  {
                    url: _paymentMODODetail.payment_deeplink,
                    callbackURL: baseUrl + 'cuenta/checkout-process-payment/modo/failure', // FAILURE URL
                    callbackURLSuccess: baseUrl + 'cuenta/checkout-process-payment/modo/success' // SUCCESS URL
                },
                callbackURL: baseUrl + 'cuenta/checkout-process-payment/modo/success', // DEBE SER = callbackURLSuccess
                refreshData: () => {
                  history.push('cuenta/checkout-process-payment/modo/failure')
                  console.error('refreshData not implemented')
                },
                onSuccess: function () { console.log('onSuccess') },
                onFailure: function () { console.log('onFailure') },
                onCancel: function () { 
                  history.push('cuenta/checkout-process-payment/modo/failure')
                },
                onClose: function () { console.log('onCLose') },
            }

            window.ModoSDK.modoInitPayment(modalObject);

          }).catch(error => {
            console.error("Failed to load the script:", error);
          });
    }


  }, []);

  return (
    <Container fluid className="inner-page checkout-payment max-container">
      <div className="breadcrumb pl-0">
        Inicio / CARRITO DE COMPRAS / <span> CHECKOUT</span>
      </div>

      {paymentError &&
        <div className="text-center">
          <h3 className="pt-5 mt-5 mb-5 ">Error al conectar con MODO</h3>
          <a href="/cuenta" className="payment-button">Volver a Mi Cuenta</a>
        </div>
      }

      <div id="iframe-section" style={{minHeight : 600, minWidth : 600}}>
          <div className="text-center">
            <h3 className="pt-5 mt-5 mb-5 ">Pago con MODO</h3>
            <h4>Si no se completa la compra correctamente, <a href={baseUrl+"cuenta/mis-pedidos"}>vuelva a intentarlo aquí</a></h4>
          </div>
      </div>

    </Container>
  )

}

export default CheckoutMODO;