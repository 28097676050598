import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../helpers/domain'


const NewsletterModal = () => {

    const [modalShow, setModalShow] = useState(false);
    const [matches, setMatches] = useState(window.matchMedia("(min-width: 475px)").matches)

    let publicUrl = PUBLIC_URL

    useEffect(() => {
        window.matchMedia("(min-width: 475px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, [])

    // Función para cerrar el modal y registrar que ya se mostró
    const handleClose = () => {
        setModalShow(false);
        // Guarda la fecha actual en localStorage cuando el modal se cierra
        localStorage.setItem('lastModalShowDate', new Date().toDateString());
    };

    // useEffect(() => {

    //     // url('images/newsletter_bkg.png')

    //     // Verifica si el modal ya se mostró hoy
    //     const lastModalShowDate = localStorage.getItem('lastModalShowDate');
    //     const today = new Date().toDateString();

    //     if (lastModalShowDate !== today) {
    //         // Si no se mostró hoy, establece un timeout para mostrar el modal después de 10 segundos
    //         const timer = setTimeout(() => {
    //             setModalShow(true);
    //         }, 15000);

    //         // Limpia el timeout en caso de que el componente se desmonte
    //         return () => clearTimeout(timer);
    //     }
    // }, []);

    useEffect(() => {
        // Verifica si el modal ya se mostró hoy
        const lastModalShowDate = localStorage.getItem('lastModalShowDate2');
        const today = new Date().toDateString();
    
        if (lastModalShowDate !== today) {
            // Carga la imagen
            // const img = new Image();
            // img.src = `${publicUrl}/images/newsletter_bkg.png`;
    
            // img.onload = () => {
            //     // Una vez que la imagen está cargada, establece un timeout para mostrar el modal después de 15 segundos
            //     const timer = setTimeout(() => {
            //         // if it is the home page, set modal true
            //         if (window.location.pathname === '/') {
            //             setModalShow(true);
            //         }
            //     }, 15000);
    
            //     // Limpia el timeout en caso de que el componente se desmonte
            //     return () => clearTimeout(timer);
            // };
            const timer = setTimeout(() => {
                // if it is the home page, set modal true
                if (window.location.pathname === '/') {
                    setModalShow(true);
                }
            }, 15000);

            // Limpia el timeout en caso de que el componente se desmonte
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        // <Modal show={modalShow} onHide={handleClose} id="modalnewsletter"> 
        //   <Modal.Header closeButton></Modal.Header>
        //   <Modal.Body style={{backgroundImage: matches ? "url('/images/newsletter_bkg.png')" : "url('/images/newsletter_bkg_mobile.png')" }}>
        //     <a href='https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH' className="btn" target='_blank' rel="noreferrer">
        //       Registrate acá
        //     </a>
        //   </Modal.Body>
        // </Modal>
        <>
            <Modal show={modalShow} onHide={handleClose} id="modalnewsletter">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{ backgroundImage: matches ? `url('${publicUrl}/images/newsletter_bkg.png')` : `url('${publicUrl}/images/newsletter_bkg_mobile.png')` }}>
                    <a href='https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH' className="btn" target='_blank' rel="noreferrer">
                        Registrate acá
                    </a>
                </Modal.Body>
            </Modal>
            {/* imagen ya oculta para que se cargue antes */}
            <img alt="newsletter" style={{ display: 'none' }}  src={matches ? `${publicUrl}/images/newsletter_bkg.png` : `${publicUrl}/images/newsletter_bkg_mobile.png`} />
        </>
    );
};

export default NewsletterModal;