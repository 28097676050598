import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { getStickyMessages } from '../actions/home'
import { FaTimes  } from 'react-icons/fa'


const defaultStyles = {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    background: '#333',
    color: '#fff',
    padding: '5px 5px',
    textAlign: 'center',
    overflow: 'hidden', // Ocultar contenido que no está visible
};

const StickyBar = () => {

    const dispatch = useDispatch()

    const [styles, setStyles] = useState({
        display : 'none',
    });

    const [messages, setMessages ] = useState([]);

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 4000); // Cambia cada 4 segundos

        return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }, [messages.length]);

    useEffect(() => {
        dispatch(getStickyMessages())
            .then(response => {
                if(!response.value.active) return;
                
                setMessages(response.value.messages)
                setStyles({
                    ...defaultStyles,
                    ...response.value.styles,
                });
            })
    }, []);

    return (
        <div className='stickybar' style={styles}>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center position-relative">
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`stickybar-message ${
                                    index === currentMessageIndex ? 'visible' : 'hidden'
                                }`}
                                dangerouslySetInnerHTML={{ __html: message }}
                            >
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
            {/* <div  onClick={() => setStyles({display: 'none'})}> */}
                <FaTimes className='close-button' onClick={() => setStyles({display: 'none'})}/>
            {/* </div> */}
        </div>
    );
};

export default StickyBar;

