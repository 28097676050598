import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { calculateShipmentCostV2, showCart, validateCoupon } from '../actions/cart'
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap'
import InputSpinner from '../components/input-spinner'
import { usePopup } from '../context/popup-context'
import CountrySelect from '../components/country-select'
import CartV2ResumenCard from './cartV2-resumen'
import CartV2DatosCard from './cartV2-datos'
import { showClientProfile } from '../actions/client'
import { confirmAddress } from '../actions/checkout'
import { createOrder } from '../actions/order'
import history from '../history'
import ReactPixel from 'react-facebook-pixel'
import CartV2EnvioCard from './cartV2-envio'
import CartV2PaymentCard from './cartv2-payment'
import ButtonLoader from '../components/button-loader'
import { MyAccordion, MyAccordionToggler, MyAccordionCollapse } from '../components/my-accordion'
import { FaSpinner } from 'react-icons/fa'

const CartV2 = () => {


	const ADDRESS_INITIAL_STATE = { name: '', last_name: '', email: '', phone: '', dni: '', street_ln_1: '', street_ln_2: '', city: '', province: '', country: '', country_code: '', zip_code: '', street_opt: '', number_opt: '', floor_opt: '', apartment_opt: '', }
	const [clientAddress, setClientAddress] = useState({
		bill_address: ADDRESS_INITIAL_STATE,
		ship_address: ADDRESS_INITIAL_STATE,
		sameBillingAddress: true,
	})
	const [clientNotes, setClientNotes] = useState('')
	const [addressErrors, setAddressErrors] = useState({})

	const { profile } = useSelector(state => state.showClientProfile)
	const { cart } = useSelector(state => state.showCart)
	const [userCurrency, setUserCurrency] = useState('ARS')

	const [needRefreshShipment, setNeedRefreshShipment] = useState(false)
	const [isCalculatingShipment, setIsCalculatingShipment] = useState(false)
	const [isMakingOrder, setIsMakingOrder] = useState(false)
	const [validationMessage, setValidationMessage] = useState('')

	const [payment, setPayment] = useState("Transferencia")

	const [shipment, setShipment] = useState("2")
	const [shipmentInfo, setShipmentInfo] = useState({
		"shipmentCountryCode": null, "shipmentCountry": null, "shipmentCity": null, "shipmentZipCode": null,
		"shipmentAmount": null, "isFreeShip": null,
		"possibleShippingOffices": { offices: [] },
	})
	const [selectedOfficeId, setSelectedOfficeId] = useState(null)

	const dispatch = useDispatch()



	const refreshShipmentIfNeeded = (eventKey) => {
		console.log("refreshShipmentIfNeeded", needRefreshShipment ? "true" : "false")
		if (needRefreshShipment) {
			setIsCalculatingShipment(true)
			setNeedRefreshShipment(false)
			if (clientAddress.ship_address.zip_code && clientAddress.ship_address.country_code && clientAddress.ship_address.city) {
				dispatch(calculateShipmentCostV2(clientAddress.ship_address.zip_code, clientAddress.ship_address.country,
					clientAddress.ship_address.country_code, clientAddress.ship_address.city
				)).then(response => {
					console.log("refreshShipmentIfNeeded", response)
					setIsCalculatingShipment(false)
					setShipmentInfo(response.value)
				})
			} else {
				console.log('No se puede calcular el envío, faltan datos')
				setIsCalculatingShipment(false)
			}
		}
	}

	useEffect(() => {
		if (!cart || cart.id == null) {
			dispatch(showCart(true))
				.then(response => {
					if (response.value?.id == null || response.value?.cartItems.length == 0) {
						history.push('/cart')
					}
				})
		}
		// if (!cart || cart.id == null) {
		//   history.push('/cart')
		// }
		if (!profile) {
			dispatch(showClientProfile())
		}
		setUserCurrency(localStorage.getItem('entelequia-currency') ?? 'ARS');
	}, [])

	useEffect(() => {
		console.log(cart)
		if (cart) {
			let productsIds = cart.cartItems.map(cartItem => cartItem.product.id)
			let productsAndQuantity = cart.cartItems.map(cartItem => ({ 'id': cartItem.product.id, 'quantity': cartItem.quantity }))
			ReactPixel.init('261944151355410')
			ReactPixel.track('InitiateCheckout', { content_ids: productsIds, contents: productsAndQuantity })
		}
	}, [cart])

	useEffect(() => {
		if (profile) {
			setClientAddress({
				...clientAddress, ship_address: profile.ship_address ?? ADDRESS_INITIAL_STATE,
				bill_address: profile.bill_address ?? ADDRESS_INITIAL_STATE
			})
			setNeedRefreshShipment(true)
		}
	}, [profile])

	const createShipStreetLn1 = (address) => {
		address.ship_address.street_ln_1 = address.ship_address.street_opt + ' ' + address.ship_address.number_opt;
		if (address.ship_address.floor_opt != '') {
			address.ship_address.street_ln_1 += ' Piso ' + address.ship_address.floor_opt;
		}
		if (address.ship_address.apartment_opt != '') {
			address.ship_address.street_ln_1 += ' Depto. ' + address.ship_address.apartment_opt;
		}
		return address;
	}

	const onMakeOrder = () => {
		setIsMakingOrder(true)
		const address = createShipStreetLn1(clientAddress)

		// console.log(address);
		// return;

		dispatch(createOrder(address, shipment, selectedOfficeId, payment, clientNotes))
			.then(response => {
				let { order, bankData } = response.value
				if (order.payment.payment_method == 'Transferencia') {
					history.push('/cuenta/checkout-complete', {
						order: order,
						bankData,
					})
				} else if (order.payment.payment_method == 'Getnet') {
					console.log("response.value", response.value);
					let { paymentGetnetDetail } = response.value
					console.log(paymentGetnetDetail, paymentGetnetDetail)
					history.push('/cuenta/checkout-getnet', {
						order: order,
						paymentGetnetDetail: paymentGetnetDetail,
					})
				} else if (order.payment.payment_method == 'MODO') {
					console.log("response.value", response.value);
					let { paymentMODODetail } = response.value
					console.log(paymentMODODetail, paymentMODODetail)
					history.push('/cuenta/checkout-modo', {
						order: order,
						paymentMODODetail: paymentMODODetail,
					})
				} else {
					if (order?.payment.payment_link) {
						// redirect to payment gateway
						window.location.href = order.payment.payment_link
					} else {
						history.push('/cuenta/checkout-make-payment', {
							order: order
						})
					}
				}
				dispatch(showCart(true))
			}).catch(error => {
				setIsMakingOrder(false)
				let { data, status } = error.response
				if (status === 422) {
					setValidationMessage(data?.message ?? "Error inesperado.")
				} else {
					setValidationMessage('Se produjo un error al realizar el pedido. Si el problema persiste, comuníquese con nostros.')
				}
			})
	}

	const verifyAddressIsComplete = (address) => {
		// Mandatory: city, country, dni, email, last_name, name, phone, province, street_ln_1, zip_code

		const fields = [ "city", "country", "dni", "email", "last_name", "name", "phone", "province", "zip_code", "street_opt", "number_opt" ];
		const errors = {};

		for (let i = 0; i < fields.length; i++) {
			if (address[fields[i]] == '') {
				errors[fields[i]] = 'Campo requerido';
			}
		}

		setAddressErrors(errors);

		if (Object.keys(errors).length > 0) {
			return false;
		}
		
		return true;

	}

	const [currentAccordionStep, setCurrentAccordionStep] = useState("0")
	const handleAccordionStepChange = (newStep) => {
		if (currentAccordionStep == "0") {
			if(verifyAddressIsComplete(clientAddress.ship_address) === false){
				// alert("Por favor complete los datos de dirección antes de continuar");
				return;
			}
			refreshShipmentIfNeeded();
		}
		setCurrentAccordionStep(newStep)
	}

	if (!cart) {
		return <Container fluid className="inner-page cart max-container">
			<FaSpinner icon="spinner" className="button-spinner animate-spin" style={{ fontSize: '50px', margin: '20px auto', display: 'block' }} />
		</Container>
	}


	return <Container fluid className="inner-page cart max-container checkoutv2">

		<h5>Resumen de compra</h5>

		<Row>

			<Col xs={12} md={8} className="mt-4">

				<MyAccordion currentActiveKey={currentAccordionStep} onActiveKeyChange={handleAccordionStepChange}>

					<CartV2DatosCard setNeedRefreshShipment={setNeedRefreshShipment} refreshShipmentIfNeeded={refreshShipmentIfNeeded}
						clientAddress={clientAddress} setClientAddress={setClientAddress} clientNotes={clientNotes} setClientNotes={setClientNotes} 
						currentAccordionStep={currentAccordionStep} addressErrors={addressErrors}
					/>

					<CartV2EnvioCard cart={cart} shipment={shipment} setShipment={setShipment} refreshShipmentIfNeeded={refreshShipmentIfNeeded}
						selectedOfficeId={selectedOfficeId} setSelectedOfficeId={setSelectedOfficeId}
						isCalculatingShipment={isCalculatingShipment} shipmentInfo={shipmentInfo} currentAccordionStep={currentAccordionStep}
					/>

					<CartV2PaymentCard userCurrency={userCurrency} payment={payment} setPayment={setPayment} currentAccordionStep={currentAccordionStep} />

				</MyAccordion>

			</Col>

			<Col xs={12} md={4} className="mt-4">

				<CartV2ResumenCard cart={cart}
					shipment={shipment} isCalculatingShipment={isCalculatingShipment} shipmentInfo={shipmentInfo} refreshShipmentIfNeeded={refreshShipmentIfNeeded}
					isMakingOrder={isMakingOrder} setIsMakingOrder={setIsMakingOrder} validationMessage={validationMessage} onMakeOrder={onMakeOrder}
				/>

			</Col>



		</Row>




	</Container>

}

export default CartV2