import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Carousel, Row, Col, Button } from 'react-bootstrap'
import InputSpinner from '../components/input-spinner'
import { Carousel as ProductCarousel } from '../components/carousel'
import { showProduct } from '../actions/product'
import useAddToCart from '../hook/add-to-cart'
import useAddToFav from '../hook/add-to-fav'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import ButtonLoader from '../components/button-loader'
import ReactPixel from 'react-facebook-pixel'
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../helpers/domain'
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'


const NewlineText = (props) => {
  let text = props.text
  let lines = text.split('\r\n');
  let finalText = [] 
  lines.forEach(e => {
    let parts = e.split('\\n')
    finalText.push(...parts)
  })
  return text? finalText.map((str,i) => <p key={i}>{str}</p>): ''
}

const Product = () => {
  const {product, isFetching} = useSelector(state => state.showProduct)
  const [cartQuantity, setCartQuantity] = useState(1)
  const dispatch = useDispatch()
  const params = useParams()
  const [isAddingToCart, addToCart ] = useAddToCart()
  const addToFav = useAddToFav()
  let apiPublicFolder = REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  let publicUrl = PUBLIC_URL


  const [carouserlIndex, setCarouselIndex] = useState(0)
  const [carouselDirection, setCarouselDirection] = useState(null)

  const handleSelectCarousel = (selectedIndex, e) => {
    console.log("aaaa")
    setCarouselIndex(selectedIndex);
    setCarouselDirection(e?.direction ?? null);
  }
  
  useEffect(() => {
    if(product){
      ReactPixel.init('261944151355410');
      ReactPixel.track('ViewContent', {content_ids: [product.id], content_type: 'product_group'});
    }
  },[product])

  useEffect(() => {
    getProduct()
  },[params])
  
  const getProduct = () => {
    window.scrollTo(0, 0);
    let productId = params.id
    dispatch(showProduct(productId))
  }

  return (
    <Container fluid className="product inner-page max-container">
      {
        isFetching === true ?
          <h3 className="mt-5 mb-5 pt-5 pb-5 border-0 text-center">Cargando...</h3>
        :
          product == null ?
            <h3 className="mt-5 mb-5 pt-5 pb-5 border-0 text-center">Producto no encontrado</h3>
          :
            <Row>
              <Col xs={12} md={6} className="product-photos mt-lg-5 pt-lg-4">
                {/* <Carousel prevIcon={FiChevronLeft} nextIcon={FiChevronRight}> */}
                <Carousel prevIcon={null} nextIcon={null}
                  activeIndex={carouserlIndex}
                  direction={carouselDirection}
                  onSelect={handleSelectCarousel}
                >
                  { product?.images.length > 0 ? 
                      product.images.map((img,i) =>  
                        <Carousel.Item key={i} className="text-center">
                          <InnerImageZoom src={`${apiPublicFolder}${img.path}`} />
                        </Carousel.Item>)
                    :
                      <Carousel.Item className="text-center">
                        <img  src={`${publicUrl}/images/nofoto.png`} alt="1" />
                      </Carousel.Item>
                  }
                </Carousel>
                {
                  product?.images.length > 0 ?
                    <div className='product-photos-thumbs'>
                      {product.images.map((img,i) => 
                        <div className='photos-thumb' key={i}>
                          <img src={`${apiPublicFolder}${img.path}`} alt="Imagen del producto"
                            onClick={() => setCarouselIndex(i)}
                          />
                        </div>
                      )}
                    </div>
                  :
                  <></>
                }
              </Col>
              <Col xs={12} md={6} className="product-info mt-5 mt-sm-0">
                <div className="breadcrumb d-none d-sm-block">
                  {
                    product?.categories.map((c,i) => 
                      <React.Fragment key={i}>
                        { i > 0 &&
                          <span>-</span>
                        }
                        { c.parent &&
                          <> <Link to={`/productos/${c.parent.slug}`}>{c.parent.name.toUpperCase()}</Link> <span className="mr-1">-</span> </> 
                        }
                        <Link to={`/productos/${c.slug}`}>{c.name}</Link>
                      </React.Fragment>
                    )
                  }
                </div>
                <h4>{product?.title}</h4>
                <div className="product-description" style={{minHeight: '216px'}}>
                  <p className="description-item"><span className="name">Peso:</span> {product?.dimensions?.weight} gr.</p>
                  <p className="description-item"><span className="name">Dimensiones:</span> {product?.dimensions?.length} x {product?.dimensions?.width} x {product?.dimensions?.height} cm</p>
                  {
                    product?.authors.length > 0 && 
                    <p className="description-item"><span className="name">Autor: </span> 
                      { product.authors.map((e,i) => 
                          <Link to={`/autores/${e.slug}`} key={i} className="author-link">
                            {product.authors.length === (i+1)? e.name : e.name+', '}
                          </Link>
                      )}
                    </p>
                  }
                  { (product?.format != null && product?.format != 'null') &&
                    <p className="description-item"><span className="name">Formato:</span> { product.format.replace('_',' ').toUpperCase() }</p>
                  }
                  { ( product?.language != null && product.language != 'null') &&
                    <p className="description-item"><span className="name">Idioma:</span> { product.language.replace('_',' ').toUpperCase() }</p>
                  }
                  { product?.brand &&
                      <p className="description-item">
                        <span className="name">Marca: </span>
                        <Link to={`/productos/?editorial=${product.brand.slug}`}>{product.brand.name}</Link>
                      </p>
                  } 
                  { (product?.number_of_pages != null && product?.number_of_pages != 'null') &&
                    <p className="description-item"><span className="name">Páginas</span> { product.number_of_pages }</p>
                  }
                  { product?.tags.length > 0 &&
                    <p className="description-item"><span className="name">Tags </span> 
                      { product.tags.map((e,i) => <Link key={i} to={`/tags/${e.slug}`} className="mr-1">{e.name}</Link> )}            
                    </p>
                  }
                  { (product?.isbn != null && product?.isbn != 'null') &&
                    <p className="description-item isbn"><span className="name">ISBN:</span> {product?.isbn}</p>
                  }
                </div>
                {product?.discount_percent ? 
                  <>
                    <div className="old-price">
                      <div><del>{product?.price.currency} {product?.price.amount}</del></div>
                      <div className="off">{product.discount_percent}% OFF</div>
                    </div>
                    <p className="product-price mb-4"> {product?.priceWithDiscount.currency} {product.priceWithDiscount.amount}</p>
                  </>
                :
                  <p className="product-price mb-4">
                    {product?.price.currency} {product?.price.amount}
                  </p>
                }
              
                {/*<img src="images/icons/rating-ex.svg" className="rating" alt="Calificación"/>*/}
                { product?.stock > 0 ? 
                  <> 
                    { 
                      product.stock == 1 ?
                        <p className='description-item mb-5'><span className="name">¡ULTIMO DISPONIBLE!</span></p>
                      :
                        <p className='description-item mb-5'>Unidades disponibles: <span className="name">{product.stock}</span></p>
                    }
                    <div className="cart-control">
                      <InputSpinner quantity={cartQuantity} onClickHandler={quantity => setCartQuantity(quantity)}/>
                      <ButtonLoader 
                        isLoading={isAddingToCart}
                        loadingMessage="Agregando..."
                        type="button" 
                        className="add-to-cart"
                        onClick={() => addToCart(product?.id,cartQuantity)}>
                          Añadir al carrito
                      </ButtonLoader> 
                    </div>
                  </>
                  :
                    <div>
                      <h4 style={{color: '#FF000E', 'fontSize': '20px'}} translate="no">Sin stock</h4>
                    </div>
                }
                <Button className="add-to-fav" onClick={() => addToFav(product?.id)}><img src={`${publicUrl}/images/icons/heart-off.svg`} alt=""/> Añadir a lista de deseos</Button>
                <div className="share">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer"><img src={`${publicUrl}/images/icons/face-grey.svg`} alt="Facebook"/></a>
                  <a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank" rel="noreferrer" ><img src={`${publicUrl}/images/icons/wap-grey.svg`} alt="Whats'app"/></a>
                  <span>Compartir</span>
                </div>
              </Col>
              <Col xs={12} className="description section">
                <h4 className="title-section">Descripción</h4>
                <NewlineText text={product?.description}/>
              </Col>
              { product?.authorProducts?.length > 0 &&
                <Col xs={12} className="related section">
                  <h4 className="title-section">Más del autor</h4>
                  <ProductCarousel
                    key={product?.id}
                    products={ product?.authorProducts}
                  />
                </Col>
              }
              <Col xs={12} className="recommended section">
                <h4 className="title-section">También te recomendamos</h4>
                <ProductCarousel 
                  key={product?.id}
                  products={product?.relatedProducts}/>
              </Col>
            </Row>
      }
    </Container>
  )
}

export default Product